import React, { FC } from "react"

import Header from "@ecom/ui/components/HeaderNew"

import scrollToForm from "../../helpers/scrollToForm"
import Logo from "./logo"
import * as styles from "./headerDoktor.module.scss"

type Props = {
  phones: string[]
  withButton?: boolean
}

const HeaderDoktor: FC<Props> = ({ phones, withButton = true }) => {
  const phoneProps = {
    phones,
  }

  const buttonProps = {
    onClick: scrollToForm,
    children: "Улучшить историю",
    classes: { root: styles.headerButton, label: styles.headerButtonLabel },
  }

  return (
    <>
      <Header
        withButton={withButton}
        sticky
        left={<Logo className={styles.logo} />}
        PhoneProps={phoneProps}
        ButtonProps={buttonProps}
        classes={{ container: styles.headerContainer }}
      />
    </>
  )
}
export default HeaderDoktor
